<template>
    <div>
      <div class="breadcrumb_header">
        <div class="breadcrumb ms-2">
          <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
          <span class="divider"></span>
          <h class="breadcrumb-text">Use Cases / Build an Argument</h>
        </div>
        <div>
          <button @click="toggleSidebar" class="open_sidebar me-2">
            <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
            <p>Open Sidebar</p>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 mt-3 p-0">
          <div class="d-flex justify-content-end cursor-pointer back" @click="goBack">
            <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
            <span style="margin: 0 0 0 8px;">Back</span>
          </div>
        </div>
        <div class="col-md-10 mt-5 p-0">
          <loader v-if="loading"></loader>
          <div v-else>
            <main class="content mt-3">
                <section class="answer-section">
                    <div class="answer-header mt-2">
                        <div class="tab d-flex gap-2 align-items-center">
                        <h6 class="answer-title">Full Summary</h6>
                        </div>
                        <div class="actions">
                        <button class="button" @click="copy"><img
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4042ce02169404fc8f24e2435141f1518abb04892a209162cf916b7ff7de4ac3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                            alt="Copy icon" class="img" />
                            <span>Copy</span>
                        </button>
                        <button class="button button-primary">
                            <img
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f2a54da00e522cdf1529d8e0426a02f942a8ec8a8d127f4f56d06f70ef396dd?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                            alt="Export icon" class="img" />
                            <span>Export</span>
                        </button>
                        </div>
                    </div>
                    </section>

                <section class="intro mt-4">
                    <div>
                        <h6 class="title">Document 1:</h6>
                        <h6>Case Summary</h6>
                    </div>

                    <b-container class="bv-summary-row mb-3 mt-4">
                        <b-row cols="2">
                            <b-col class="question mb-3">Case Title<br>
                                <p class="answer-text">
                                    Smith v. Johnson Contract Dispute
                                </p>
                            </b-col>
                            <b-col class="question mb-3">Case Number
                                <p class="answer-text">
                                    2023-CV-145
                                </p>
                            </b-col>
                            <b-col class="question mb-3">Court
                                <p class="answer-text">
                                    Superior Court of California, Los Angeles County
                                </p>
                            </b-col>
                            <b-col class="question mb-3">Presiding Judge
                                <p class="answer-text">
                                    Hon. Susan Taylor
                                </p>
                            </b-col>
                            <b-col class="question mb-3">Plaintiff
                                <p class="answer-text">
                                    John Smith
                                </p>
                            </b-col>
                            <b-col class="question mb-3">Defendant
                                <p class="answer-text">
                                    Mary Johnson
                                </p>
                            </b-col>
                        </b-row>
                    </b-container>
                  <hr class="separator" />
                </section>

                <div class="tabs">
                <h6 class="title">Key Points</h6>
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question mt-3">Legal Issues</h6>
                </div>
                <div class="pt-2">
                  <ul>
                    <li class="mb-2">Did the force majeure clause in the contract excuse Johnson’s delayed performance?</li>
                    <li>Did the force majeure clause in the contract excuse Johnson’s delayed performance?</li>
                  </ul>

                  <div>
                    <h6 class="question mt-4 mb-3">Factual Disputes</h6>
                    <p class="answer-text mt-1">
                      Dispute:<span class="ms-2" style="color: #383A3E !important;">When did Johnson notify Smith of the delivery delay?</span>
                    </p>
                    <p class="answer-text mt-1">
                      Suggestion Action:<span class="ms-2" style="color: #383A3E !important;">Further discovery might be needed to clarify the timeline and any communication gaps. Look into email or phone records to establish whether the delay notice was provided within a reasonable timeframe.</span>
                    </p>
                    <p class="answer-text mt-1">
                      Dispute:<span class="ms-2" style="color: #383A3E !important;">When did Johnson notify Smith of the delivery delay?</span>
                    </p>
                    <p class="answer-text mt-1">
                      Suggestion Action:<span class="ms-2" style="color: #383A3E !important;">Further discovery might be needed to clarify the timeline and any communication gaps. Look into email or phone records to establish whether the delay notice was provided within a reasonable timeframe.</span>
                    </p>
                  </div>

                  <div>
                  <h6 class="question mt-4 mb-3">Key Evidence</h6>
                    <p class="answer-text mt-1">
                    Dispute:<span class="ms-2" style="color: #383A3E !important;">When did Johnson notify Smith of the delivery delay?</span>
                  </p>
                  <p class="answer-text mt-1">
                    Suggestion Action:<span class="ms-2" style="color: black#383A3E !important;">Further discovery might be needed to clarify the timeline and any communication gaps. Look into email or phone records to establish whether the delay notice was provided within a reasonable timeframe.</span>
                  </p>
                  <p class="answer-text mt-1">
                    Dispute:<span class="ms-2" style="color: #383A3E !important;">When did Johnson notify Smith of the delivery delay?</span>
                  </p>
                  <p class="answer-text mt-1">
                    Suggestion Action:<span class="ms-2" style="color: #383A3E !important;">Further discovery might be needed to clarify the timeline and any communication gaps. Look into email or phone records to establish whether the delay notice was provided within a reasonable timeframe.</span>
                  </p>
                  </div>
                </div>
              </div>


            </main>
          </div>
          <!-- {{ files }} -->
        </div>
        <div class="col-md-1">
        </div>
      </div>
      <sidebar v-if="sidebarCollapsed" @toggle-sidebar="toggleSidebar" :uploadFiles="files"></sidebar>
    </div>
  </template>
  <script>
  // import { sanitizeHTML } from "../../../store/utils";
//   import ChatBox from "../../../components/input/ChatBox.vue";
  import countryImages from '../../../assets/country/index';
  import loader from './deep-research-loader.vue';
  import sidebar from '../sidebar.vue';
  import usecase from '@/store/usecase.js';
  import nexa from '@/store/nexa.js';
  
  export default {
    components: {
      loader,
      sidebar,
    //   ChatBox,
    },
    data() {
      return {
        loading: true,
        duration: -1,
        interval: null,
        sidebarCollapsed: false,
        lawbotVisible: false,
        isCountry: false,
        showSummary: false,
        countryImages,
        files: [],
        questions: '',
        question: '',
        answer: '',
        legislations: {},
        case_laws: {},
        discussion: '',
        doctrines: [],
        jurisdiction: '',
        activeTab: 'case_laws',
        followUpQuestion: '',
  
        optionsContent: {
          modules: {
            toolbar: [
              ["bold", "italic", "underline"],
              [{ color: [] }, { background: [] }],
              [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            ],
          },
        },
      }
    },
    methods: {
      toggleSummary() {
        this.showSummary = !this.showSummary;
      },
      toggleSidebar() {
        this.sidebarCollapsed = !this.sidebarCollapsed;
      },
      goBack() {
        this.$router.push({ name: 'BuildArgumentResult' });
      },
      editQuestion() {
        this.$router.push({ name: 'DeepResearchEdit', params: { id: this.$route.params.id } });
      },
      toggleCountry() {
        this.isCountry = !this.isCountry;
      },
      showAttachModal() {
        this.$bvModal.show('attachfile');
      },
      hideAttachModal() {
        this.$bvModal.hide('attachfile');
      },
      copy() {
        navigator.clipboard.writeText(this.discussion);
        this.$toast.success("Copied");
      },
      handleSubmit() {
        if (this.followUpQuestion.length > 0) {
          const payload = {
            language: 'en',
            location_id: 1,
            question: this.followUpQuestion
          };
  
          nexa.DetailAsk(this.$route.params.id, payload)
            .then((response) => {
              const newResponse = response.data.data;
              console.log(newResponse);
              this.followUpQuestion = '';
              this.$toast.success("Successful");
            })
            .catch((error) => {
              console.error("Error sending question:", error);
            });
        }
      },
      formatDateTime(date) {
        return new Date(date).toISOString();
      },
      getTimeDifference(date) {
        const now = new Date();
        const createdAt = new Date(date);
        const diffInMinutes = Math.floor((now - createdAt) / (1000 * 60));
  
        if (diffInMinutes < 60) {
          return `${diffInMinutes} m`;
        } else {
          const diffInHours = Math.floor(diffInMinutes / 60);
          return `${diffInHours} h`;
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    created() {
      usecase.GetResult(this.$route.params.id ?? '').then((response) => {
        const DATA = response.data.data;
        console.log(DATA)
        if (DATA.completedPerCentage >= 100) {
          this.loading = false;
        }
  
        if (DATA.completedPerCentage < 100) {
          if (DATA.completedPerCentage > 0) this.duration = DATA.completedPerCentage;
  
          this.interval = setInterval(() => {
            usecase.GetResult(this.$route.params.id).then((res) => {
              if (res.data.data.completedPerCentage >= 100) {
                this.duration = res.data.data.completedPerCentage;
                clearInterval(this.interval);
                location.reload();
              } else if (res.data.data.completedPerCentage > 0) {
                this.duration = res.data.data.completedPerCentage;
              }
            });
          }, 10000);
        } else {
          this.duration = 100;
          this.question = DATA.questions[0].question;
          this.answer = DATA.questions[0].answer;
          this.jurisdiction = Object.keys(JSON.parse(DATA.jurisdiction))[0];
          this.legislations = JSON.parse(DATA.values.find(el => el.key === 'nexa_legislations').value);
          this.case_laws = JSON.parse(DATA.values.find(el => el.key === 'nexa_caselaws').value);
          this.files = DATA.questionDocuments;
          
          this.discussion = JSON.parse(DATA.values.find(el => el.key === 'nexa_discussion').value)
            .replace(/\\n/g, '\n') // Convert literal '\n' to actual newline
            .replace(/^\s*##\s*(.*?)\s*$/gm, '<br><span style="font-weight:500;font-size:15px;color:#0E4485">$1</span><br>') // Make lines starting with ### or #### bold
            .replace(/^\s*#\s*(.*?)\s*$/gm, '<br><span class="h6" style="color:#0E4485">$1</span><br>') // Make lines starting with ### or #### bold
            .replace(/\n/g, '<br>') // Replace actual newline characters with <br>
            .replace(/\n+/g, '<br>') // Replace multiple actual newlines with a single <br>
            .replace(/\*\*(.*?)\*\*/g, '<span style="font-weight:500;color:#0E4485">$1</span>') // Convert text between ** to <b>
            .replace(/\*/g, '') // Remove any remaining asterisks (single)
            .replace(/\\/g, '<br>') // Replace backslashes with <br>;
          this.doctrines = JSON.parse(DATA.values.find(el => el.key === 'nexa_doctrines').value);
          this.introduction = JSON.parse(DATA.values.find(el => el.key === 'nexa_introduction').value);
          this.conclusion = JSON.parse(DATA.values.find(el => el.key === 'nexa_conclusion').value);
          this.nexa = JSON.parse(DATA.values.find(el => el.key === 'nexa_discussion').value);
          this.file_value = JSON.parse(DATA.values.find(el => el.key === 'nexa_files').value);
          this.nexa_questions = DATA.questions;
  
          nexa.AllResult().then(response => { 
            const DATA = response.data.data.data;
            this.questions = DATA.map(item => {
              return {
                id: item.id,
                title: item.title,
                fileCount: item.file_uploaded_count,
                createdAt: item.created_at,
              };
            });
          }).catch(error => {
            console.error('Error fetching AllResult:', error);
          });
        }
      });
    }
  }
  </script>
  
  <style scoped>
  .page-content {
    padding: 0px;
    overflow-x: hidden;
  }
  
  .overlay-container {
    width: 25%;
    position: absolute;
    top: 8.7vh;
    right: 0;
    z-index: 1000;
    height: 100vh;
  }
  
  .breadcrumb_header{
        display: flex;
        justify-content: space-between;
        height: 68px;
        background: #fff;
        /* padding: 16px 20px; */
        align-items: center;
      }
      
      .breadcrumb {
        display: flex;
        align-items: center;
        margin: auto 0;
      }
      .breadcrumb-text{
        color: var(--Neutral-Dark-Grey, #86888D);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .open_sidebar {
        display: flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
      
        border-radius: 4px;
        background: var(--Primary-Blue, #0E4485);
      }
  
  .open_sidebar span {
    color: #fff;
    display: flex;
    /* Ensure icon is aligned vertically */
    align-items: center;
    /* Align icon vertically within span */
  }
  
  .open_sidebar p {
    color: #fff !important;
    margin: 0;
    /* Remove any default margin to align the text properly */
    line-height: 1;
    /* Ensure text has the same line height as the icon */
    display: flex;
    align-items: center;
    /* Align text vertically */
  }
  
  .divider {
    display: inline-block;
    margin: 0px 16px;
    width: 1px;
    height: 32px;
    background-color: var(--mid-grey);
  }
  
  .back {
    color: var(--primary) !important;
    font-weight: 500;
  }
  </style>
  <style scoped>
  .content {
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
    display: flex;
    flex-direction: column;
    padding: 22px 24px;
  }
  
  @media (max-width: 991px) {
    .content {
      padding: 0 20px;
    }
  }
  
  .intro,
  .question-section,
  .answer-section,
  .discussion,
  .doctorine,
  .citations,
  .legal-references {
    max-width: 100%;
  }
  
  .quillEditor .ql-toolbar.ql-snow{
  border-radius: 8px 0px !important;
  border-bottom: 1px solid #D1D2D5 !important;
  background: #F2F3F3 !important;
  }
  
  .legal-references {
    margin-bottom: 5rem;
  }
  
  .title {
    color: var(--Primary-Blue, #0e4485) !important;
    font: 500 16px/28px Poppins, sans-serif;
  }
  
  .separator {
    background: #f2f3f3;
    height: 1px;
    width: 100%;
  }
  
  .question-section .tabs,
  .answer-section .answer-header, 
  .legal-references {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 25px
  }
  
  .result-tabs {
    display: flex;
    width: 100%;
    background: var(--Neutral-Light-Grey, #f2f3f3);
    align-items: center;
  }
  
  .question .button,
  .actions .button,
  .tab {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    border-radius: 4px;
  }
  
  h6.title.answer-title.case-title {
    color: var(--Primary-Blue, #0e4485) !important;
    margin: 0px;
  }
  
  .img {
    aspect-ratio: 1/1;
    width: 18px;
    object-fit: contain;
  }
  
  .question-description,
  .answer-text {
    margin-top: 4px;
    color: var(--Neutral-Dark-Grey, #86888d) !important;
    font: 400 14px/24px Poppins, sans-serif;
  }
  
  .doctorine-text{
    color: var(--Neutral-Dark-Grey, #86888D);
  
    /* Button */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .text-link{
    color: var(--Status-Blue, #1890FF);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
    
  }
  
  .answer-title,
  .reference-title {
    color: var(--Primary-Blue, #0e4485) !important;
    font-size: 16px;
    line-height: 28px;
  }
  
  .actions .button {
    border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
    background: var(--Neutral-White, #fff);
    color: var(--Primary-Dark-Blue, #000046) !important;
  }
  
  .actions .button-primary {
    background: var(--Primary-Blue, #0e4485);
    color: var(--Neutral-White, #fff) !important;
  }
  
  .actions .button,
  .actions .button-primary {
    padding: 2px 8px;
  }
  
  .details .detail {
    color: var(--Neutral-Dark-Grey, #86888d) !important;
    font-size: 14px;
  }
  
  .detail-title {
    color: var(--Primary-Blue, #0e4485) !important;
    font-size: 16px;
  }
  
  .detail-text {
    margin-top: 4px;
    color: var(--Neutral-Dark-Grey, #86888d) !important;
  }
  
  .link {
    color: var(--Status-Blue, #1890ff) !important;
  }
  
  .results {
    background: var(--Neutral-Interface-Grey, #fafbfc);
    border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
    border-radius: 8px;
  }
  
  .result-tabs .tab {
    background: var(--Neutral-Light-Grey, #f2f3f3);
    color: var(--Neutral-Dark-Grey, #86888D);
    padding: 8px 16px;
    font-weight: 500;
    border-radius: 0px !important;
  }
  
  .result-tabs .tab.active {
    border-bottom: 2px solid var(--Primary-Blue, #0e4485);
    color: var(--Primary-Blue, #0e4485) !important;
  }
  
  .case-result {
    border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    padding: 20px 16px;
    margin-top: 20px;
  }
  
  .case-title {
    font-size: 16px;
    color: var(--Neutral-Black, #383a3e) !important;
    font-weight: 500;
  }
  
  .case-details .meta {
    display: flex;
    align-items: center;
  }
  
  .case-details .meta img {
    margin-right: 4px;
  }
  
  .case-overview,
  .case-analysis p {
    font-size: 14px;
    color: var(--Neutral-Dark-Grey, #86888d) !important;
    line-height: 24px;
  }
  
  .relevancy {
    display: flex;
    align-items: center;
    color: var(--Status-Green, #52c41a) !important;
  }
  
  .relevancy img {
    margin-right: 4px;
  }
  
  .actions {
    display: flex;
    gap: 2px;
  }
  </style>
  
  <style scoped>
  .follow-up-question {
    justify-content: center;
    border-radius: 16px;
    border: 1px solid var(--Primary-Blue, #0e4485);
    background: var(--Neutral-White, #fff);
    box-shadow: 0 2px 10px 0 rgba(14, 68, 133, 0.6);
    z-index: 10;
    display: flex;
    width: 74%;
    gap: 8px;
    flex-wrap: wrap;
    padding: 12px 20px;
    align-items: center;
    position: fixed;
    bottom: 20px;
  }
  
  .input-field {
    align-self: stretch;
    min-width: 240px;
    flex: 1;
    padding: 10px 16px 10px 0;
    font: 400 16px Poppins, sans-serif;
    border: none;
    outline: none;
    background: transparent;
  }
  
  .follow-up-actions {
    display: flex;
    padding-right: 20px;
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 56px;
  }
  
  .submit-button {
    border-radius: 4px;
    align-self: stretch;
    display: flex;
    width: 36px;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    padding: 6px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .submit-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    align-self: stretch;
  }
  
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  @media (max-width: 991px) {
  
    .follow-up-question,
    .input-field {
      max-width: 100%;
    }
  }
  </style>