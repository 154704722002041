import { render, staticRenderFns } from "./deep-research-loader.vue?vue&type=template&id=e3023b84&scoped=true"
var script = {}
import style0 from "./deep-research-loader.vue?vue&type=style&index=0&id=e3023b84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3023b84",
  null
  
)

export default component.exports